<template>
  <div>
    <v-form ref="form">
      <div class="mt-10">
        <data-table
          :value="tabelaChecklist.dados"
          show-gridlines
          class="p-datatable-sm"
          :rows="50"
          :scrollable="true"
          scroll-height="500px"
          data-key="id"
          :row-hover="true"
          :selection.sync="tabelaChecklist.selecionados"
          filter-display="menu"
          responsive-layout="scroll"
        >
          <Column
            field="check"
            header=""
            :styles="{ 'min-width': '215px' }"
          >
            <template #body="slotProps">
              <input-radio
                :key="keyAtualizaRadio"
                v-model="slotProps.data.check"
                class="col-12 pt-0"
                :options="opcoes.booleano"
                @input="
                  alteraOpcaoOkNaoOk(slotProps.data.check, slotProps.index)
                "
              />
            </template>
          </Column>
          <Column
            field="descricao"
            :header="$t('modulos.operacao.tabela.descricao')"
          />
          <Column
            field="acaoNaoOk"
            :header="$t('modulos.operacao.tabela.acao_nao_ok')"
          />
          <Column
            field="servicos"
            :header="$t('modulos.operacao.tabela.servicos')"
          >
            <template #body="slotProps">
              <div>
                {{ slotProps.data.servicos.length }}
              </div>
            </template>
          </Column>
          <Column
            field="pecas"
            :header="$t('modulos.operacao.tabela.pecas')"
          >
            <template #body="slotProps">
              <div>
                {{ slotProps.data.pecas.length }}
              </div>
            </template>
          </Column>
        </data-table>
      </div>
      <div
        class="d-flex mt-4"
        style="justify-content: space-between"
      >
        <botao-padrao
          color="secondary"
          outlined
          @click="voltaStep()"
        >
          <v-icon>$mdiArrowLeft</v-icon>
          {{ $t('modulos.contrato.formulario.itensContrato.steps.voltar') }}
        </botao-padrao>

        <botao-padrao
          color="primary"
          @click="proximoStep()"
        >
          {{ $t('modulos.contrato.formulario.itensContrato.steps.proximo') }}
          <v-icon>$arrowRight</v-icon>
        </botao-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import _ from 'lodash';
export default {
  components: {},
  props: {
    form: { type: Object, default: () => ({}) },
    dadosChecklistSelecionado: { type: Array, default: () => [] },
    prazoGarantia: { type: Number, default: null },
    participanteId: { type: String, default: null },
    instrumentoId: { type: String, default: null },
    codigoInstrumento: { type: String, default: null },
    codigoOrdemServico: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
  },
  data() {
    return {
      keyAtualizaRadio: 1,
      opcoes: {
        booleano: [
          {
            text: 'Ok',
            value: true,
          },
          {
            text: 'Não Ok',
            value: false,
          },
        ],
      },
      editingRows: [],
      tabelaChecklist: {
        selecionados: [],
        dados: [],
        colunas: [],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    instrumentoId() {
      this.listarServicosChecklist();
    },
  },
  mounted() {
    this.listarServicosChecklist();
  },
  methods: {
    alteraOpcaoOkNaoOk(opcaoRadio, index) {
      this.form.checklist = [];
      this.form.dadosChecklistResumo = [];
      if (this.tabelaChecklist.dados.length) {
        this.form.dadosChecklistResumo = this.tabelaChecklist.dados;
        this.tabelaChecklist.dados.map((itemChecklist, indexDados) => {
          if (
            indexDados == index &&
            (itemChecklist.servicos.length || itemChecklist.pecas.length) &&
            !opcaoRadio
          ) {
            this.confirmarAdicionarAutomaticamenteServicosPecas().then(() => {
              if (itemChecklist.servicos.length) {
                this.$emit('envia-servicos-checklist', itemChecklist.servicos);
              }
              if (itemChecklist.pecas.length) {
                this.$emit('envia-pecas-checklist', itemChecklist.pecas);
              }
            });
          }

          this.form.checklist[indexDados] = {
            sequencia: itemChecklist.sequencia,
            descricao: itemChecklist.descricao,
            acaoNaoOk: itemChecklist.acaoNaoOk,
            flagOk: opcaoRadio,
          };
        });
      }
    },
    voltaStep() {
      this.$emit('volta-step', 1);
    },
    proximoStep() {
      this.$emit('proximo-step', 3);
    },
    listarServicosChecklist() {
      if (this.instrumentoId) {
        this.$store.dispatch('Layout/iniciarCarregamento');
        InstrumentoService.buscaChecklist(this.instrumentoId)
          .then((res) => {
            if (res.data.tipoChecklist == 'AnaliseTecnica') {
              const itens = _.cloneDeep(res.data.itens);
              this.tabelaChecklist.dados = _.sortBy(itens, 'sequencia');
              this.tabelaChecklist.paginaAtual = 1;
              this.tabelaChecklist.porPagina =
                this.tabelaChecklist.dados.length;
            }
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
            if (this.dadosChecklistSelecionado?.length)
              this.selecionaChecklistDaEdicao();
          });
      }
    },
    selecionaChecklistDaEdicao() {
      this.tabelaChecklist.dados[0].check =
        this.dadosChecklistSelecionado[0].flagOk;

      this.keyAtualizaRadio++;
    },
  },
};
</script>
