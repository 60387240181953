<template>
  <div>
    <div class="row d-flex">
      <div class="titulo-pagina col-12 col-md-8 mt-4">
        {{ $t('modulos.operacao.formulario.abas.analise_tecnica') }}
      </div>

      <div class="titulo-pagina col-12 col-md-4">
        <div class="d-flex" style="place-content: space-between">
          <div>{{ $t('modulos.operacao.formulario.valor_total') }}</div>
          <div>
            {{ progressBarInfo }}
          </div>
        </div>
        <v-progress-linear
          :key="keyAtualiza"
          :value="percentualTotal"
          rounded
          height="20"
          background-color="#E4E7EC"
          :color="colorProgressBar"
        />
      </div>
    </div>
    <div class="d-flex mb-2">
      <v-chip class="mr-1" small>
        {{ $t('modulos.operacao.steps.instrumento') }}:
        {{ `${codigoInstrumento}` }}
      </v-chip>
      <v-chip class="mr-1" small>
        {{ $t('modulos.operacao.steps.tipo') }}: {{ `${nomeTipoInstrumento}` }}
      </v-chip>
      <v-chip class="mr-1" small>
        {{ $t('modulos.operacao.steps.ordem_servico') }}:
        {{ `${codigoOrdemServico}` }}
      </v-chip>
    </div>
    <v-stepper v-model="e1" non-linear style="box-shadow: none">
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step :complete="e1 > 1" step="1" editable>
          {{ $t('modulos.operacao.steps.calibracao') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="e1 > 2" step="2" editable>
          {{ $t('modulos.operacao.steps.checklist') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="e1 > 3" step="3" editable>
          {{ $t('modulos.operacao.steps.manutencao') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="e1 > 4" step="4" editable>
          {{ $t('modulos.operacao.steps.pecas') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="5" editable>
          {{ $t('modulos.operacao.steps.resumo') }}
        </v-stepper-step>
      </v-stepper-header>

      <div v-if="e1 !== 5" class="row mt-3 mr-3" style="justify-content: end">
        <botao-padrao
          v-if="e1 == 4 && buscarPermissao('Item', 'Inserir')"
          class="mr-2"
          @click="abreModalNovoItemPeca"
        >
          {{ $t('modulos.operacao.formulario.novo_item_peca') }}
        </botao-padrao>
        <botao-padrao
          v-if="e1 == 4"
          class="mr-2"
          @click="abreModalVistaExplodida"
        >
          {{ $t('modulos.operacao.formulario.vista_explodida') }}
        </botao-padrao>
        <botao-padrao
          v-if="buscarPermissao('Instrumento', 'Editar')"
          class="mr-2"
          @click="abreModalEditarInstrumento"
        >
          {{ $t('modulos.operacao.formulario.instrumento') }}
        </botao-padrao>
        <botao-padrao
          v-if="buscarPermissao('Instrumento', 'VisualizarHistorico')"
          class="mr-2"
          @click="abrirInstrumentoHistoricoServicosPecas"
        >
          {{ $t('modulos.instrumento.botoes.historico_servicos_pecas') }}
        </botao-padrao>
        <botao-padrao
          v-if="buscarPermissao('Instrumento', 'Editar')"
          class="mr-2"
          @click="abreModalNovoPontoCalibracao"
        >
          {{ $t('modulos.operacao.formulario.pontos_calibracao') }}
        </botao-padrao>
        <botao-padrao
          v-if="buscarPermissao('CriterioAceitacao', 'Listar')"
          class="mr-2"
          @click="abreModalCriteriosAceitacao"
        >
          {{ $t('modulos.operacao.formulario.criterios_aceitacao') }}
        </botao-padrao>
        <botao-padrao class="mr-3" @click="abreModalRequisitos">
          {{ $t('modulos.operacao.formulario.abas.requisitos') }}
        </botao-padrao>
      </div>

      <v-stepper-items>
        <v-stepper-content step="1">
          <dados-calibracao
            ref="dados-analise-tecnica"
            :form="form"
            :desabilita-garantia="desabilitaGarantia"
            :participante-id="participanteId"
            :instrumento-id="instrumentoId"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :ordem-servico-id="ordemServico"
            :opcoes-tipo-calibracao="opcoesTipoCalibracao"
            :opcoes-local-execucao="opcoesLocalExecucao"
            :atualiza-listagem-calibracao="atualizaListagemCalibracao"
            :dados-calibracao-selecionados="dadosCalibracaoSelecionados"
            :local-execucao-instalacao-cliente="localExecucaoInstalacaoCliente"
            :cliente-participante-ordem-servico-id="
              clienteParticipanteOrdemServicoId
            "
            @valida-dados-analise-tecnica="validaFormularioDadosAnaliseTecnica"
            @calcula-valor-total="calculaValorTotal"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <checklist
            ref="checklist"
            :form="form"
            :participante-id="participanteId"
            :instrumento-id="instrumentoId"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :dados-checklist-selecionado="dadosChecklistSelecionado"
            @volta-step="mudaStep"
            @proximo-step="mudaStep"
            @envia-servicos-checklist="servicosChecklist"
            @envia-pecas-checklist="pecasChecklist"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <listagem-manutencao
            ref="listagem-manutencao"
            :form="form"
            :participante-id="participanteId"
            :instrumento-id="instrumentoId"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :servicos-ids="servicosIds"
            :dados-manutencao-selecionados="dadosManutencaoSelecionados"
            @volta-step-2="mudaStep"
            @proximo-step-4="mudaStep"
            @calcula-valor-total="calculaValorTotal"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <listagem-pecas
            ref="listagem-pecas"
            :form="form"
            :participante-id="participanteId"
            :instrumento-id="instrumentoId"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :id-tipo-instrumento="idTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :pecas-ids="pecasIds"
            :dados-pecas-selecionadas="dadosPecasSelecionadas"
            :atualiza-listagem-pecas="atualizaListagemPecas"
            :hotspot-clicado-imagem="hotspotClicadoImagem"
            @volta-step-3="mudaStep"
            @proximo-step-5="mudaStep"
            @calcula-valor-total="calculaValorTotal"
          />
        </v-stepper-content>
        <v-stepper-content step="5">
          <resumo-analise-tecnica
            ref="resumo-analise-tecnica"
            :form="form"
            :opcoes-tipo-calibracao="opcoesTipoCalibracao"
            :opcoes-local-execucao="opcoesLocalExecucao"
            :nome-tipo-instrumento="nomeTipoInstrumento"
            :codigo-instrumento="codigoInstrumento"
            :codigo-ordem-servico="codigoOrdemServico"
            :atualiza-listagem-resumo="atualizaListagemResumo"
            @volta-step-4="mudaStep"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao class="mr-2" @click="salvar(false)">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
      <botao-padrao @click="salvar(true)">
        <v-icon>$mdiCheck</v-icon>
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
    </div>
    <requisito-modal
      ref="modal-requisito"
      :requisitos-cliente="dadosRequisitosClienteFormatado"
    />
    <novo-ponto-calibracao-modal
      ref="modal-novo-ponto-calibracao"
      :instrumento-id="instrumentoId"
      :nome-tipo-instrumento="nomeTipoInstrumento"
      :codigo-instrumento="codigoInstrumento"
      :codigo-ordem-servico="codigoOrdemServico"
      @atualizarInstrumento="buscaValorInstrumento"
    />
    <instrumento-edicao-modal
      ref="modal-edicao-instrumento"
      :instrumento-id="instrumentoId"
      :atualiza="true"
      @atualiza-instrumento-analise-tecnica="atualizaInstrumentoAnaliseTecnica"
    />
    <criterios-aceitacao-modal
      ref="modal-criterios-aceitacao"
      :instrumento-id="instrumentoId"
    />
    <novo-item-peca-modal
      ref="modal-novo-item-peca"
      :id-tipo-instrumento="idTipoInstrumento"
      :nome-tipo-instrumento="nomeTipoInstrumento"
      :id-modelo-instrumento="idModeloInstrumento"
      :nome-modelo-instrumento="nomeModeloInstrumento"
      :id-unidade-medida-instrumento="idUnidadeMedidaInstrumento"
      :nome-unidade-medida-instrumento="nomeUnidadeMedidaInstrumento"
      @atualizaListagemPeca="atualizaListagemPeca"
    />
    <ModalServicosPecas ref="modal-historico-servicos-pecas" />

    <vista-explodida-modal
      v-if="vistaExplodidaClicada"
      ref="modal-vista-Explodida"
      :id-modelo-instrumento="idModeloInstrumento"
      @hotspotClickedDados="hotspotClickedDados"
      @modalFechado="modalFechado"
    />
  </div>
</template>
<script>
import OrdemServicoService from '@common/services/cadastros/OrdemServicoService';
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import UtilsService from '@common/services/UtilsService';
import TipoCalibracaoService from '@common/services/cadastros/TipoCalibracaoService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { AnaliseTecnicaModel } from '@common/models/cadastros/AnaliseTecnicaModel';
import DadosCalibracao from './DadosCalibracao';
import ListagemManutencao from './ListagemManutencao';
import Checklist from './Checklist';
import ListagemPecas from './ListagemPecas';
import ResumoAnaliseTecnica from './ResumoAnaliseTecnica';
import { RequisitoModal } from '@components/requisitos';
import NovoPontoCalibracaoModal from './modais/NovoPontoCalibracaoModal.vue';
import InstrumentoEdicaoModal from './modais/InstrumentoEdicaoModal.vue';
import CriteriosAceitacaoModal from './modais/CriteriosAceitacaoModal.vue';
import AnaliseTecnicaService from '@common/services/cadastros/AnaliseTecnicaService';
import NovoItemPecaModal from './modais/NovoItemPecaModal.vue';
import VistaExplodidaModal from './modais/VistaExplodidaModal.vue';
import ModalServicosPecas from '../../instrumento/components/modais/ModalServicosPecas.vue';
import helpers from '@common/utils/helpers';
import _ from 'lodash';

export default {
  components: {
    DadosCalibracao,
    ListagemManutencao,
    ListagemPecas,
    ResumoAnaliseTecnica,
    RequisitoModal,
    NovoPontoCalibracaoModal,
    InstrumentoEdicaoModal,
    Checklist,
    CriteriosAceitacaoModal,
    NovoItemPecaModal,
    ModalServicosPecas,
    VistaExplodidaModal,
  },
  props: ['idInstrumento', 'ordemServico', 'id'],
  data() {
    return {
      hotspotClicadoImagem: null,
      percentualTotal: null,
      progressBarInfo: '',
      keyAtualiza: 1,
      dadosCalibracaoSelecionados: {},
      dadosChecklistSelecionado: [],
      dadosManutencaoSelecionados: [],
      dadosPecasSelecionadas: [],
      servicosIds: [],
      pecasIds: [],
      opcoesLocalExecucao: null,
      desabilitaGarantia: null,
      atualizaListagemCalibracao: 1,
      atualizaListagemPecas: 1,
      atualizaListagemResumo: 1,
      opcoesTipoCalibracao: null,
      idModeloInstrumento: null,
      nomeModeloInstrumento: null,
      idUnidadeMedidaInstrumento: null,
      nomeUnidadeMedidaInstrumento: null,
      dadosRequisitosClienteFormatado: null,
      valorTotal: null,
      valorDoIntrumento: 0,
      participanteId: null,
      instrumentoId: null,
      codigoInstrumento: null,
      nomeTipoInstrumento: null,
      idTipoInstrumento: null,
      codigoOrdemServico: null,
      clienteParticipanteOrdemServicoId: null,
      formInstrumento: null,
      vistaExplodidaClicada: false,
      e1: 1,
      localExecucaoInstalacaoCliente: false,
      instalacoesClienteId: [],
      form: new AnaliseTecnicaModel({
        calibracao: {},
        manutencoes: [],
        pecas: [],
      }),
      watcher: {
        flagTemServicoCalibracao: null,
      },
    };
  },
  computed: {
    colorProgressBar(){
      if(this.percentualTotal<= 30 || !this.percentualTotal) return '#039855';
      if(this.percentualTotal <= 50) return '#eab308';
      return '#ef4444';
    }
  },
  watch: {
    e1() {
      if (this.e1 == 5) {
        this.atualizaListagemResumo++;
      }
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'CriterioAceitacao', 'Listar');
  },
  mounted() {
    if (this.id) {
      this.buscaDadosAnaliseTecnica();
      this.form.id = this.id;
    } else {
      this.form.flagSemConserto = 6;
      this.definirWatcher();
    }

    this.listarLocalExecucao();
    this.listarTipoCalibracao();
    this.buscaDadosInstrumento();
    this.buscaDadosInstrumentoOrdemServico(
      this.idInstrumento,
      this.ordemServico
    );
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.operacao.titulos.analise_tecnica')
    );

    setTimeout(() => {
      this.calculaValorTotal();
    }, 400);
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    hotspotClickedDados(dados) {
      this.hotspotClicadoImagem = dados;
    },
    modalFechado(valor) {
      this.vistaExplodidaClicada = !valor;
    },
    abrirInstrumentoHistoricoServicosPecas: function () {
      this.$refs['modal-historico-servicos-pecas'].abrirModal({
        id: this.instrumentoId,
      });
    },
    definirWatcher: async function () {
      if (this.watcher.flagTemServicoCalibracao) {
        this.watcher.flagTemServicoCalibracao();
      }
      this.watcher.flagTemServicoCalibracao = this.$watch(
        'form.flagTemServicoCalibracao',
        () => {
          this.form.calibracao = {};
        }
      );
      setTimeout(() => {
        this.$refs['dados-analise-tecnica']?.setarWatcher();
      }, 300);
    },
    calculaValorTotal: function () {
      this.valorTotal = 0;
      this.calculaValorCalibracao();
      this.calculaValorManutencoes();
      this.calculaValorPecas();
      this.percentualProgressBar();
    },
    calculaValorCalibracao() {
      if (!this.form.calibracao?.valor) return;
      this.calculaPontosExtraCalibracao();
      this.valorTotal += this.form.calibracao.valor;
      this.calculaHorasExtrasCalibracao();
    },
    calculaPontosExtraCalibracao() {
      if (!this.form?.pontosExtras || !this.form.calibracao?.valorPonto) return;
      this.valorTotal +=
        this.form.pontosExtras * this.form.calibracao.valorPonto;
    },
    calculaHorasExtrasCalibracao() {
      if (!this.form.calibracao.valorHora) return;
      const { horasExecucao, horasExecucaoCustom, valorHora } =
        this.form.calibracao;
      const diferencaHoras = horasExecucaoCustom - horasExecucao;
      const valorHoraExecucao = diferencaHoras * valorHora;
      if (diferencaHoras <= 0) return;

      this.valorTotal += valorHoraExecucao || 0;
    },
    calculaValorManutencoes() {
      if (!this.form.manutencoes?.length) return;
      this.form.manutencoes.forEach((manutencao) => {
        if (manutencao.servico?.valor)
          this.valorTotal += manutencao.servico.valor;
        this.calculaPontosExtraManutencao(manutencao);
        this.calculaHorasExtrasManutencao(manutencao);
      });
    },
    calculaPontosExtraManutencao(manutencao) {
      const pontosInstrumento = this.form.pontosCalibracaoInstrumento ?? 0;
      const pontosExtras = pontosInstrumento - manutencao.servico?.minimoPontos;
      if (pontosExtras <= 0 || !manutencao.servico?.valorPonto) return;
      this.valorTotal += pontosExtras * manutencao.servico.valorPonto;
    },
    calculaHorasExtrasManutencao(manutencao) {
      if (!manutencao.servico?.valorHora) return;
      const { tempoExecucao, tempoExecucaoCustom, valorHora } =
        manutencao.servico;
      const diferencaHoras = tempoExecucaoCustom - tempoExecucao;
      const valorHoraExecucao = diferencaHoras * valorHora;
      if (diferencaHoras <= 0) return;
      this.valorTotal += valorHoraExecucao || 0;
    },
    calculaValorPecas() {
      if (!this.form.pecas?.length) return;
      this.form.pecas.forEach((item) => {
        const quantidade = parseFloat(item.quantidadeOrcada) || 0;
        if (item?.valor) this.valorTotal += item.valor * quantidade;
      });
    },
    percentualProgressBar() {
      this.percentualTotal = 0;
      if (!this.valorDoIntrumento) {
        return;
      }
      if (this.valorTotal) {
        this.percentualTotal = Number(
          (this.valorTotal / this.valorDoIntrumento) * 100
        ).toFixed(2);
      } else this.percentualTotal = 0;

      if (this.percentualTotal < 1 && this.percentualTotal > 0.5)
        this.percentualTotal = 1;

      this.percentualTotal = Math.trunc(this.percentualTotal);
      this.keyAtualiza++;
      this.progressBarInformation();
    },
    progressBarInformation() {
      if (!this.valorDoIntrumento) {
        this.progressBarInfo = 'Instrumento sem valor';
        return;
      }
      this.progressBarInfo = `${this.percentualTotal}%`;
    },
    buscaDadosAnaliseTecnica() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      AnaliseTecnicaService.buscar(this.id)
        .then((res) => {
          this.form = new AnaliseTecnicaModel(res.data);
          if (!this.form.calibracao) {
            this.form.calibracao = {
              tipoCalibracaoId: null,
            };
            this.form.flagTemServicoCalibracao = false;
          }
          this.form.manutencoes = [];
          this.form.pecas = [];
          this.form.checklist = res.data.checklists;
          res.data.manutencoes.forEach((item) => {
            this.form.manutencoes.push({
              id: item.id,
              servico: {
                id: item.servicoId,
                tempoExecucao: item.tempoExecucao,
                tempoExecucaoCustom: item.tempoExecucao,
              },
            });
          });
          res.data.pecas.forEach((item) => {
            this.form.pecas.push({
              id: item.id,
              pecaId: item.itemId,
              quantidadeOrcada: item.quantidadeOrcada,
              horasExecucao: item.tempoExecucao,
            });
          });
          _;
          this.dadosCalibracaoSelecionados = this.form.calibracao; // _.cloneDeep(this.form.calibracao);
          this.dadosManutencaoSelecionados = this.form.manutencoes;
          this.dadosPecasSelecionadas = this.form.pecas;
          this.dadosChecklistSelecionado = res.data.checklists;

          if (!this.form.flagGarantia) {
            this.form.flagGarantia = 2;
          } else this.form.flagGarantia = 1;

          if (this.form.flagSemConserto == false) this.form.flagSemConserto = 6;
          else {
            if (this.form.justificativaSemConserto == 'SemCalibracao') {
              this.form.flagSemConserto = 'SemCalibracao';
            }
            if (this.form.justificativaSemConserto == 'SemConserto') {
              this.form.flagSemConserto = 'SemConserto';
            }
            if (
              this.form.justificativaSemConserto == 'NaoCompensaFinanceiramente'
            ) {
              this.form.flagSemConserto = 'NaoCompensaFinanceiramente';
            }
            if (
              this.form.justificativaSemConserto == 'ForaDoEscopoCalibracao'
            ) {
              this.form.flagSemConserto = 'ForaDoEscopoCalibracao';
            }
            if (
              this.form.justificativaSemConserto == 'ForaDoEscopoManutencao'
            ) {
              this.form.flagSemConserto = 'ForaDoEscopoManutencao';
            }
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.definirWatcher();
        });
    },
    validarServicosPecasComConcerto() {
      if (this.form.flagSemConserto !== 6) return true;
      if (
        this.form.calibracao?.servicoId ||
        this.form.manutencoes.length ||
        this.form.pecas.length
      )
        return true;

      this.toastAlerta(
        this.$t(`modulos.operacao.erros.salvar_com_concerto_sem_servico_pecas`)
      );
      return false;
    },
    salvar(flagFinalizada) {
      const form = _.cloneDeep(this.form);
      if (!this.$refs['dados-analise-tecnica'].$refs.form.validate()) {
        this.e1 = 1;
        return;
      }
      if (!this.$refs['resumo-analise-tecnica'].$refs.form?.validate()) {
        this.e1 = 5;
        return;
      }

      form.calibracao.horasExecucao =
        parseFloat(form.calibracao?.horasExecucaoCustom) || 0;

      form.manutencoes?.forEach(
        (el) =>
          (el.servico.tempoExecucao =
            parseFloat(el.servico.tempoExecucaoCustom) || 0)
      );

      if (!this.validarServicosPecasComConcerto()) return;

      this.$store.dispatch('Layout/iniciarCarregamento');
      form.valorTotal = this.valorTotal;
      form.instrumentoId = this.idInstrumento;
      form.ordemServicoId = this.ordemServico;

      if (form.flagSemConserto == 6) {
        form.flagSemConserto = false;
        form.justificativaSemConserto = '';
      } else {
        this.toastAlerta(
          this.$t(
            `modulos.operacao.erros.salvar_sem_concerto_perde_servico_pecas`
          )
        );
      }
      if (form.flagSemConserto == 'SemCalibracao') {
        form.flagSemConserto = true;
        form.justificativaSemConserto = 'SemCalibracao';
      }
      if (form.flagSemConserto == 'SemConserto') {
        form.flagSemConserto = true;
        form.justificativaSemConserto = 'SemConserto';
      }
      if (form.flagSemConserto == 'NaoCompensaFinanceiramente') {
        form.flagSemConserto = true;
        form.justificativaSemConserto = 'NaoCompensaFinanceiramente';
      }
      if (form.flagSemConserto == 'ForaDoEscopoCalibracao') {
        form.flagSemConserto = true;
        form.justificativaSemConserto = 'ForaDoEscopoCalibracao';
      }
      if (form.flagSemConserto == 'ForaDoEscopoManutencao') {
        form.flagSemConserto = true;
        form.justificativaSemConserto = 'ForaDoEscopoManutencao';
      }
      if (form.flagGarantia == 1) form.flagGarantia = true;
      if (form.flagGarantia == 2) form.flagGarantia = false;
      form.flagFinalizada = flagFinalizada;

      let formEnviar = { ...form };
      if (
        JSON.stringify(formEnviar.calibracao) === '{}' ||
        !formEnviar.calibracao?.tipoCalibracaoId ||
        formEnviar.calibracao?.servicoId == null
      ) {
        formEnviar.calibracao = null;
      }
      AnaliseTecnicaService.salvar(formEnviar)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.analise_tecnica.salvo_com_sucesso`)
          );
          this.$router.push({ name: 'operacao' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abreModalNovoItemPeca() {
      this.$refs['modal-novo-item-peca'].abrirModal();
    },
    abreModalVistaExplodida() {
      this.vistaExplodidaClicada = true;
      setTimeout(() => {
        this.$refs['modal-vista-Explodida'].abrirModal();
      }, 2000);
    },
    abreModalCriteriosAceitacao() {
      this.$refs['modal-criterios-aceitacao'].abrirModal();
    },
    abreModalEditarInstrumento() {
      this.$refs['modal-edicao-instrumento'].abrirModal();
    },
    buscaDadosInstrumento() {
      OrdemServicoService.buscar(this.ordemServico)
        .then((res) => {
          if (res.data) {
            this.clienteParticipanteOrdemServicoId =
              res.data.clienteParticipante?.id;
            this.codigoOrdemServico = res.data.codigo;
            this.participanteId = res.data.solicitanteParticipanteId;
            this.buscaRequisitos(res.data.clienteParticipante?.id);
            this.instrumentoId = this.idInstrumento;

            if (!this.form.id) this.buscaPrazoEntregaTipoPrazoAutomatico();

            res.data.ordensServicosInstrumentos.map((item) => {
              if (item.instrumentoId == this.idInstrumento) {
                this.buscaValorInstrumento(item.instrumentoId);
                this.codigoInstrumento = item.codigoTag;
                this.nomeTipoInstrumento = item.tipoInstrumento.nome;
                this.idTipoInstrumento = item.tipoInstrumento.id;
              }
            });

            if (res.data.localExecucao && !this.id) {
              this.form.localExecucaoId = res.data.localExecucao.id;
              this.verificarLocalExecucaoInstalacaoCliente(
                res.data.localExecucao.id
              );
            }
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    verificarLocalExecucaoInstalacaoCliente(id) {
      if (!id) return;
      setTimeout(() => {
        if (this.instalacoesClienteId.includes(id)) {
          this.localExecucaoInstalacaoCliente = true;
        }
      }, 200);
    },
    buscaPrazoEntregaTipoPrazoAutomatico() {
      if (this.instrumentoId && this.clienteParticipanteOrdemServicoId) {
        let params = {
          instrumentoId: this.instrumentoId,
          participanteId: this.clienteParticipanteOrdemServicoId,
          ordemServicoId: this.ordemServico,
        };
        AnaliseTecnicaService.buscaPrazoEntregaTipoPrazo(params)
          .then((res) => {
            if (res.data.prazoEntrega) {
              this.form.prazoEntregaEmDias = res.data.prazoEntrega;
            } else this.listarDadosPadrao();

            this.form.tipoPrazoEntrega = res.data.tipoPrazoEntrega;
          })
          .catch((err) => {
            this.toastErro(
              this.$t(`geral.erros.${err.response.data.errors[0]}`)
            );
          });
      }
    },
    listarDadosPadrao() {
      UtilsService.buscarDadosPadrao(this.id)
        .then((res) => {
          this.form.prazoEntregaEmDias = res.data.prazoEntregaPadrao;
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    buscaDadosInstrumentoOrdemServico(id, ordemServico) {
      OrdemServicoService.buscaDadosOrdemServicoInstrumento(id, ordemServico)
        .then((res) => {
          if (res.data.dentroPrazoGarantia) {
            this.desabilitaGarantia = null;
            this.toastAlerta(
              this.$t(`modulos.operacao.erros.instrumento_prazo_garantia`)
            );
          } else {
            this.desabilitaGarantia = true;
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    servicosChecklist(servicosIds) {
      this.servicosIds = servicosIds;
    },
    pecasChecklist(pecasIds) {
      this.pecasIds = pecasIds;
    },
    atualizaInstrumentoAnaliseTecnica() {
      this.buscaDadosInstrumento();
      this.atualizaListagemCalibracao++;
    },
    listarLocalExecucao() {
      UtilsService.buscaLocalExecucao()
        .then((res) => {
          res.data.forEach((el) => {
            if (el.instalacaoCliente) this.instalacoesClienteId.push(el.id);
          });
          this.opcoesLocalExecucao = new DropdownModel(res.data);
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    listarTipoCalibracao() {
      TipoCalibracaoService.listar()
        .then((res) => {
          this.opcoesTipoCalibracao = new DropdownModel(res.data);
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        });
    },
    abreModalNovoPontoCalibracao() {
      this.$refs['modal-novo-ponto-calibracao'].abrirModal(this.instrumentoId);
    },
    mudaStep(step) {
      this.e1 = step;
    },
    validaFormularioDadosAnaliseTecnica() {
      if (this.$refs['dados-analise-tecnica'].$refs.form)
        this.valido = this.$refs['dados-analise-tecnica'].$refs.form.validate();
      if (this.valido) this.e1 = 2;
      else return;
    },
    abreModalRequisitos() {
      this.$refs['modal-requisito'].abrirModal();
    },
    buscaRequisitos(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.buscaRequisitosParticipante(id, 'tipoRequisito=2')
        .then((res) => {
          if (res?.data) {
            this.dadosRequisitosClienteFormatado = res?.data;
            this.dadosRequisitosClienteFormatado.length &&
              this.abreModalRequisitos();
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscaValorInstrumento(id) {
      InstrumentoService.buscar(id)
        .then((res) => {
          this.codigoInstrumento = res.data.codigoTag;
          this.nomeTipoInstrumento = res.data.tipoInstrumento.nome;
          this.idTipoInstrumento = res.data.tipoInstrumento.id;
          this.form.pontosCalibracaoInstrumento = 0;
          this.idModeloInstrumento = res.data.modeloInstrumento?.id;
          this.nomeModeloInstrumento = res.data.modeloInstrumento?.nome;
          if (res.data) {
            this.valorDoIntrumento = res.data.valorInstrumento;
            if (res.data.instrumentoFaixa.length) {
              this.idUnidadeMedidaInstrumento =
                res.data.instrumentoFaixa[0]?.unidadeMedida?.id;
              this.nomeUnidadeMedidaInstrumento =
                res.data.instrumentoFaixa[0]?.unidadeMedida?.nome;
              res.data.instrumentoFaixa.forEach((item) => {
                if (item.pontos.length)
                  this.form.pontosCalibracaoInstrumento += item.pontos.length;
              });
            }
          }
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.percentualProgressBar();
          this.$refs['dados-analise-tecnica']?.atualizarPontosExtras();
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'operacao' });
      });
    },
    atualizaListagemPeca() {
      this.atualizaListagemPecas++;
    },
  },
};
</script>
