<template>
  <div>
    <v-form ref="form">
      <div class="mt-10">
        Selecione as peças que deseja substituir no instrumento.
        <tabela-padrao-prime-vue
          :key="keyAtualiza"
          v-model="tabelaPecas.selecionados"
          :dados="tabelaPecas.dados"
          filtro-geral
          class="mt-2"
          :colunas="tabelaPecas.colunas"
          paginacao-em-memoria
          sem-paginacao
          :filters="filters"
          ajustar-altura-linha
          :mostrar-acoes="false"
          :por-pagina="tabelaPecas.porPagina"
          :pagina-atual="tabelaPecas.paginaAtual"
          :global-filters="tabelaPecas.colunas.map(c => c.value)"
        >
          <template v-slot:quantidadeOrcada="{ slotProps }">
            <input-text 
              v-model="slotProps.data.quantidadeOrcada"
              sem-label
              flat
              solo
              type="number"
              class="flex-fill input-tabela-analise-tecnica my-1"
              apenas-numeros
              @input="atualizaQuantidadeOrcada"
            />
          </template>
        </tabela-padrao-prime-vue>
      </div>
      <div
        class="d-flex mt-4"
        style="justify-content: space-between"
      >
        <botao-padrao
          color="secondary"
          outlined
          @click="voltaStep()"
        >
          <v-icon>$mdiArrowLeft</v-icon>
          {{ $t('modulos.contrato.formulario.itensContrato.steps.voltar') }}
        </botao-padrao>

        <botao-padrao
          color="primary"
          @click="proximoStep()"
        >
          {{ $t('modulos.contrato.formulario.itensContrato.steps.proximo') }}
          <v-icon>$arrowRight</v-icon>
        </botao-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
import ItemService from '@common/services/cadastros/ItemService';
import OmieService from '@common/services/cadastros/OmieService';
import { FilterMatchMode } from 'primevue/api';
import helpers from '@common/utils/helpers';

import _ from 'lodash';
export default {
  components: {},
  props: {
    form: { type: Object, default: () => ({}) },
    pecasIds: { type: Array, default: () => [] },
    dadosPecasSelecionadas: { type: Array, default: () => [] },
    prazoGarantia: { type: Number, default: null },
    participanteId: { type: String, default: null },
    instrumentoId: { type: String, default: null },
    codigoInstrumento: { type: String, default: null },
    codigoOrdemServico: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
    idTipoInstrumento: { type: String, default: null },
    atualizaListagemPecas: { type: Number, default: 1 },
    hotspotClicadoImagem: { type: Object, default: ()=>{} },
  },
  data() {
    return {
      validaQuantidadeOrcada: true,
      keyAtualiza: 0,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      formInstrumento: null,
      precoServicoContrato: null,
      codigoString: '',
      tabelaPecas: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.operacao.tabela.peca'),
            sorteable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.operacao.tabela.nome'),
            sorteable: true,
          },
          {
            value: 'participanteFabricante.nome',
            text: this.$t('modulos.operacao.tabela.fabricante'),
            sorteable: true,
          },
          {
            value: 'quantidadeEstoque',
            text: this.$t('modulos.operacao.tabela.quantidade_estoque'),
            sorteable: true,
          },
          {
            value: 'prazoEntrega',
            text: this.$t('modulos.operacao.tabela.prazo_entrega'),
            sorteable: true,
          },
          {
            value: 'tempoExecucao',
            text: this.$t('modulos.operacao.tabela.horas_execucao'),
            sorteable: true,
            formatter: v => v && helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'quantidadeOrcada',
            text: this.$t('modulos.operacao.tabela.quantidade_orcada'),
            sorteable: true,
          },
          {
            value: 'ultimaExecucao',
            text: this.$t('modulos.operacao.tabela.ultima_execucao'),
            sorteable: true,
            formatter: v => v ? helpers.formatarDataBr(v) : '',
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    hotspotClicadoImagem(){
      const peca = this.tabelaPecas.dados.find(peca => peca.id === this.hotspotClicadoImagem.id);
      const pecajaSelecionada = this.tabelaPecas
        .selecionados.find(peca =>peca.id === this.hotspotClicadoImagem.id)
      
      if(peca && !pecajaSelecionada){
        const pecaSelecionada = {
          ...this.hotspotClicadoImagem,
          quantidadeOrcada:peca.quantidadeOrcada
        }
        this.tabelaPecas.selecionados.push(pecaSelecionada)
      }

      if(pecajaSelecionada){
        this.tabelaPecas.selecionados = 
          this.tabelaPecas.selecionados
          .filter(peca => peca.id !== this.hotspotClicadoImagem.id)
      }
       
    },
    atualizaListagemPecas() {
      this.listarPecas();
      this.$store.dispatch(
        'Layout/alterarTituloPagina',
        this.$t('modulos.operacao.titulos.analise_tecnica')
      );
    },
    async 'tabelaPecas.selecionados'() {
      await this.atualizaDadosFormularioPecas();
      this.$emit('calcula-valor-total');
    },
    pecasIds() {
      if (this.pecasIds.length > 0) {
        this.selecionaPecasChecklist(this.pecasIds);
      }
    },
    dadosPecasSelecionadas() {
      this.selecionaPecasDaEdicao();
    },
    instrumentoId() {
      if (this.instrumentoId) this.listarPecas();
    },
  },
  methods: {
    atualizaQuantidadeOrcada(){
      this.atualizaDadosFormularioPecas();
    },
    selecionaPecasChecklist(servicosIds) {
      this.tabelaPecas.dados.forEach((itemPeca) => {
        servicosIds.forEach((pecaId) => {
          if (itemPeca.id == pecaId) {
            this.tabelaPecas.selecionados.push(itemPeca);
          }
        });
      });
    },
    atualizaDadosFormularioPecas() {
      this.form.pecas = [];
      this.form.dadosPecasResumo = [];
      if (this.tabelaPecas.selecionados.length) {
         
        this.form.dadosPecasResumo = this.tabelaPecas.selecionados;
        this.tabelaPecas.selecionados.map((itemPeca) => {
          this.form.pecas.push({
            pecaId: itemPeca.id,
            valor: itemPeca.valorVenda,
            quantidadeOrcada: itemPeca.quantidadeOrcada,
            horasExecucao: itemPeca.tempoExecucao,
          });
        });
      }
      this.$emit('calcula-valor-total');
    },
    voltaStep() {
      this.$emit('volta-step-3', 3);
    },
    proximoStep() {
      this.validaQuantidadeOrcada = true;
      this.tabelaPecas.selecionados.map((item) => {
        if (item.quantidadeOrcada == 0) {
          this.validaQuantidadeOrcada = false;
        }
      });
      if (!this.validaQuantidadeOrcada) {
        this.toastAlerta(
          this.$t(`modulos.operacao.erros.quantidade_orcada_nao_pode_zero`)
        );
        return;
      } else {
        this.$emit('proximo-step-5', 5);
      }
    },
    listarPecas() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      let params = {
        flagEhPeca: true,
        instrumentosId: [this.instrumentoId],
      };
      ItemService.listar(params)
        .then((res) => {
          this.tabelaPecas.dados = _.cloneDeep(res.data.items);
          this.tabelaPecas.dados.forEach((item) => {
            item.quantidadeOrcada = 1;
          });
          this.tabelaPecas.quantidadeItens = res.data.totalItems;
          this.tabelaPecas.quantidadePaginas = res.data.totalPages;
          this.tabelaPecas.paginaAtual = 1;
          this.tabelaPecas.porPagina = 10;

          this.codigoString = '';
          if (res.data.items.length) {
            res.data.items.map((item) => {
              this.codigoString += `&Codigos=${item.codigo}`;
            });
          }

          this.montaListagemPecas(this.codigoString);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if (this.dadosPecasSelecionadas?.length)
            this.selecionaPecasDaEdicao();
        });
    },
    selecionaPecasDaEdicao() {
      this.tabelaPecas.selecionados = [];
      this.tabelaPecas.dados.forEach((item) => {
        this.dadosPecasSelecionadas.forEach((itemEdicao) => {
          if (item.id == itemEdicao.pecaId) {
            this.tabelaPecas.selecionados.push(item);
          }
        });
      });

      this.tabelaPecas.selecionados.map((item) => {
        this.dadosPecasSelecionadas.forEach((itemEdicao) => {
          if (item.id == itemEdicao.pecaId) {
            item.quantidadeOrcada = itemEdicao.quantidadeOrcada;
          }
        });
      });
      ++this.keyAtualiza;
    },
    montaListagemPecas(codigoString) {
      if (codigoString) {
        let parametros = {
          flagFiltrarCodigos: true,
        };
        this.$store.dispatch('Layout/iniciarCarregamento');
        OmieService.buscaDadosOmie(codigoString, parametros)
          .then((res) => {
            this.tabelaPecas.dados.map((item) => {
              if (res.data.produtos.length) {
                res.data.produtos.map((itemProduto) => {
                  if (item.codigo === itemProduto.codigo)
                    item.quantidadeEstoque = itemProduto.saldo;
                });
              }
            });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
  },
};
</script>