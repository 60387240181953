<template>
  <div>
    <v-form ref="form">
      <div class="row mt-1">
        <input-select
          v-model="form.localExecucaoId"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.local_execucao')"
          :options="opcoes.localExecucao"
          :loading="loadingLocalExecucao"
          :obrigatorio="form.flagSemConserto == 6"
          :disabled="true"
          @input="buscaDadosListagemCalibracao"
        />
        <input-select
          v-model="form.tipoPrazoEntrega"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.tipo_prazo_entrega')"
          :options="opcoes.tipoPrazoEntrega"
          :loading="loadingLocalExecucao"
          :disabled="localExecucaoInstalacaoCliente"
          obrigatorio
        />
        <input-text
          v-model="form.prazoEntregaEmDias"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.prazo_entrega')"
          type="number"
          :disabled="localExecucaoInstalacaoCliente"
          :obrigatorio="form.flagSemConserto == 6"
          @blur="alteraTipPrazoParaArbitrario"
        />
      </div>
      <div class="row mt-1">
        <input-select
          v-model="form.flagGarantia"
          class="col-12 col-md-3"
          :label="$t('modulos.operacao.formulario.garantia')"
          :options="opcoes.booleano"
          :disabled="desabilitaGarantia"
        />
        <input-text
          v-model="form.justificativaGarantia"
          class="col-12 col-md-9"
          :label="$t('modulos.operacao.formulario.justificativa_garantia')"
          :disabled="form.flagGarantia != 1"
          :obrigatorio="form.flagGarantia == 1"
        />
      </div>
      <div class="row mt-1">
        <div class="col-md-2">
          {{ $t('modulos.operacao.formulario.possui_calibracao') }}
          <v-switch v-model="form.flagTemServicoCalibracao" />
        </div>
        <input-select
          :key="keyAtualiza"
          v-model="form.calibracao.tipoCalibracaoId"
          class="col-md-4"
          :label="$t('modulos.operacao.formulario.tipo_calibracao')"
          :options="opcoes.tipoCalibracaoEnum"
          :disabled="!form.flagTemServicoCalibracao"
          :obrigatorio="form.flagTemServicoCalibracao"
        />
        <input-text
          v-model="form.pontosCalibracaoInstrumento"
          :label="
            $t('modulos.operacao.formulario.pontos_calibracao_instrumento')
          "
          class="col-md-3"
          type="number"
          disabled
        />
        <input-text
          v-model="form.pontosExtras"
          :label="$t('modulos.operacao.formulario.pontos_extras')"
          class="col-md-3"
          type="number"
          disabled
        />
      </div>
      <div class="col-12">
        Selecione os serviços que deseja executar no instrumento.
        <tabela-padrao-prime-vue
          :key="keyAtualizaTabela"
          v-model="tabelaCalibracao.selecionados"
          :dados="tabelaCalibracao.dados"
          class="mt-2"
          :colunas="tabelaCalibracao.colunas"
          sem-paginacao
          selecionar-apenas-um
          :mostrar-seletor="form.flagTemServicoCalibracao"
          paginacao-em-memoria
          ajustar-altura-linha
          :mostrar-acoes="false"
          :por-pagina="tabelaCalibracao.porPagina"
          :pagina-atual="tabelaCalibracao.paginaAtual"
          filtro-geral
          :filters="filters"
          :global-filters="tabelaCalibracao.colunas.map(c => c.value)"
        >
          <template v-slot:tempoExecucaoCustom="{ slotProps }">
            <input-text
              v-model="slotProps.data.tempoExecucaoCustom"
              sem-label
              flat
              solo
              type="number"
              class="flex-fill my-1 input-tabela-analise-tecnica"
            />
            <!-- @input="v => verificarInputHoras(v, slotProps.data.id)" -->
          </template>
          <template v-slot:faixa="{ slotProps }">
            <div
              v-if="slotProps.data.amplitudeMinima || slotProps.data?.amplitudeMaxima"
              class="alinhar-direita"
            >
            {{
              faixaFormatada(`(${ slotProps.data?.amplitudeMinima ? slotProps.data.amplitudeMinima + ' a ' : ''}
            ${ slotProps.data?.amplitudeMaxima || '...'})
            ${slotProps.data?.unidadeMedida?.simbolo || '' }`)
            }}
            </div>
          </template>
        </tabela-padrao-prime-vue>
      </div>
      <div class="d-flex justify-end mt-2">
        <botao-padrao
          color="primary"
          @click="validaFormulario()"
        >
          {{ $t('modulos.contrato.formulario.itensContrato.steps.proximo') }}
          <v-icon>$arrowRight</v-icon>
        </botao-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
import ServicoService from '@common/services/cadastros/ServicoService';
import { InstrumentoModel } from '@common/models/cadastros/InstrumentoModel';
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import { FilterMatchMode } from 'primevue/api';
import AnaliseTecnicaService from '@common/services/cadastros/AnaliseTecnicaService';
import _ from 'lodash';
import helpers from '../../../../../common/utils/helpers';
export default {
  components: {},
  props: {
    form: { type: Object, default: () => ({}) },
    dadosCalibracaoSelecionados: { type: Object, default: () => ({}) },
    opcoesLocalExecucao: { type: Array, default: () => [] },
    opcoesTipoCalibracao: { type: Array, default: () => [] },
    prazoGarantia: { type: Number, default: null },
    atualizaListagemCalibracao: { type: Number, default: 1 },
    participanteId: { type: String, default: null },
    instrumentoId: { type: String, default: null },
    clienteParticipanteOrdemServicoId: { type: String, default: null },
    ordemServicoId: { type: String, default: null },
    codigoInstrumento: { type: String, default: null },
    codigoOrdemServico: { type: String, default: null },
    nomeTipoInstrumento: { type: String, default: null },
    desabilitaGarantia: { type: Boolean, default: null },
    localExecucaoInstalacaoCliente: { type: Boolean, default: false },
  },
  data() {
    return {
      keyAtualiza: 1,
      keyAtualizaTabela: 100,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      formInstrumento: null,
      precoServicoContrato: null,
      codigoString: '',
      habilitaPontosItens: true,
      loadingTipoCalibracao: false,
      loadingLocalExecucao: false,
      opcoes: {
        localExecucao: [],
        tipoCalibracaoEnum: [],
        booleano: [
          {
            text: 'Sim',
            value: 1,
          },
          {
            text: 'Não',
            value: 2,
          },
        ],
        tipoPrazoEntrega: [
          {
            text: 'Contrato',
            value: 'Contrato',
          },
          {
            text: 'Requisitos do cliente',
            value: 'RequisitosCliente',
          },
          {
            text: 'Calculado por Setor',
            value: 'CalculadoSetor',
          },
          {
            text: 'Arbitrário',
            value: 'Arbitrario',
          },
        ],
      },
      tabelaCalibracao: {
        selecionados: null,
        dados: [],
        colunas: [{
          value: 'codigo',
          text: this.$t('modulos.operacao.tabela.codigo_servico'),
        },
        {
          value: 'nome',
          text: this.$t('modulos.operacao.tabela.nome'),
        },
        {
          value: 'faixa',
          text: this.$t('modulos.operacao.tabela.faixa'),
        },
        {
          value: 'minimoPontos',
          text: this.$t('modulos.operacao.tabela.quantidade_pontos'),
        },
        {
          value: 'tempoExecucao',
          text: this.$t('modulos.operacao.tabela.horas_execucao_padrao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'tempoExecucaoCustom',
          text: this.$t('modulos.operacao.tabela.horas_execucao'),
        },
        {
          value: 'contrato.codigo',
          text: this.$t('modulos.operacao.tabela.codigo_contrato'),
        }],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      validaValorAnteriorCalibracao: false,
      temUltimoServicoCalibracao: false,
      idUltimoServicoCalibracao: null,
      watcherAtivo: false,
    };
  },
  watch: {
    'form.calibracao.tipoCalibracaoId'(valorAtual, valorAnterior) {
      if(valorAnterior === undefined) return this.buscaDadosListagemCalibracao();
      if (!valorAtual || !valorAnterior) return;

      if (this.validaValorAnteriorCalibracao) {
        this.validaValorAnteriorCalibracao = false;
        return;
      }
      if (!this.tabelaCalibracao.dados.length) return this.buscaDadosListagemCalibracao();

      this.confirmarTrocaTipoCalibracao()
        .then(() => {
          this.tabelaCalibracao.selecionados = null;
          this.buscaDadosListagemCalibracao();
        })
        .catch(() => {
          this.form.calibracao.tipoCalibracaoId = valorAnterior;
          this.validaValorAnteriorCalibracao = true;
          return;
        });
    },
    'tabelaCalibracao.selecionados': {
      handler(){
        if(!this.watcherAtivo) return;
        this.form.dadosCalibracaoResumo = [];
        if (this.tabelaCalibracao.selecionados) {
          if (
            this.tabelaCalibracao.selecionados.minimoPontos >
            this.form.pontosCalibracaoInstrumento
          ) {
            this.form.pontosExtras = 0;
          } else {
            this.form.pontosExtras =
              this.form.pontosCalibracaoInstrumento -
              this.tabelaCalibracao.selecionados.minimoPontos;
          }
          this.form.dadosCalibracaoResumo.push(
            this.tabelaCalibracao.selecionados
          );
          this.form.contratoId = this.tabelaCalibracao.selecionados.contrato?.id;
          this.form.calibracao.servicoId = this.tabelaCalibracao.selecionados.id;

          this.form.calibracao.valor = this.precoServicoContrato
            ? this.precoServicoContrato
            : this.tabelaCalibracao.selecionados.valorServico;

          this.form.calibracao.minimoPontos =
            this.tabelaCalibracao.selecionados.minimoPontos;
          this.form.calibracao.valorPonto =
            this.tabelaCalibracao.selecionados.valorPonto;

          this.form.calibracao.horasExecucaoCustom =
            this.tabelaCalibracao.selecionados.tempoExecucaoCustom;

          this.form.calibracao.horasExecucao =
            this.tabelaCalibracao.selecionados.tempoExecucao;

          this.form.calibracao.valorHora = this.tabelaCalibracao.selecionados.valorHora;

          if (this.tabelaCalibracao.selecionados.valorPonto > 0) {
            this.habilitaPontosItens = false;
          } else {
            this.habilitaPontosItens = true;
            this.form.calibracao.pontosItens = null;
          }
        }

        this.$emit('calcula-valor-total');
      },
      deep: true,
    },
    'form.flagTemServicoCalibracao'(value) {
      if (value) {
        this.keyAtualiza++;
        this.buscaDadosListagemCalibracao();
      } else {
        this.tabelaCalibracao.selecionados = null;
        this.tabelaCalibracao.dados = []
        this.form.calibracao = {
          tipoCalibracaoId: null
        };
        this.form.pontosExtras = null;
      }
      this.keyAtualizaTabela++;
    },
    atualizaListagemCalibracao() {
      this.buscaDadosListagemCalibracao();
    },
    opcoesLocalExecucao() {
      this.opcoes.localExecucao = this.opcoesLocalExecucao;
    },
    opcoesTipoCalibracao() {
      this.opcoes.tipoCalibracaoEnum = this.opcoesTipoCalibracao;
    },
    instrumentoId() {
      if (!this.form.id) {
        this.buscarDadosUltimoServicoETipoCalibracao(this.instrumentoId);
      } else this.buscaDadosListagemCalibracao();
    },
    'form.tipoPrazoEntrega'(value, oldValue) {
      if (value && !oldValue) return;
      this.buscaPrazoEntregaTipoPrazoAutomatico();
    },
  },
  mounted() {
    this.form.flagGarantia = 2;
    this.form.flagSemConserto = 1;
    this.form.flagTemServicoCalibracao = true;
  },
  methods: {
    // verificarInputHoras(valor, id){
    //   let servico;
    //   if(this.tabelaCalibracao.selecionados?.id == id) servico = this.tabelaCalibracao.selecionados;
    //   if(servico && servico?.tempoExecucaoCustom != valor) servico.tempoExecucaoCustom = valor;
    // },
    setarWatcher(){
      if(this.form.calibracao.horasExecucao && this.tabelaCalibracao.selecionados?.tempoExecucaoCustom)
        this.tabelaCalibracao.selecionados.tempoExecucaoCustom = this.form.calibracao.horasExecucao;
      this.watcherAtivo = true;
    },
    atualizarPontosExtras() {
      if(!this.tabelaCalibracao.selecionados) return;
      if (this.tabelaCalibracao.selecionados.minimoPontos >
        this.form.pontosCalibracaoInstrumento)
            return this.form.pontosExtras = 0;

        this.form.pontosExtras = this.form.pontosCalibracaoInstrumento -
          this.tabelaCalibracao.selecionados.minimoPontos;
    },
    alteraTipPrazoParaArbitrario() {
      this.form.tipoPrazoEntrega = 'Arbitrario';
    },
    buscaPrazoEntregaTipoPrazoAutomatico() {
      if (this.form.tipoPrazoEntrega == 'Arbitrario') return;
      if (this.instrumentoId && this.clienteParticipanteOrdemServicoId) {
        let params = {
          instrumentoId: this.instrumentoId,
          participanteId: this.clienteParticipanteOrdemServicoId,
          tipoPrazoEntrega: this.form.tipoPrazoEntrega,
          ordemServicoId: this.ordemServicoId,
        };
        AnaliseTecnicaService.buscaPrazoEntregaTipoPrazo(params)
          .then((res) => {
            this.form.prazoEntregaEmDias = res.data.prazoEntrega;
            this.form.tipoPrazoEntrega = res.data.tipoPrazoEntrega;
          })
          .finally(() => {});
      }
    },
    buscarDadosUltimoServicoETipoCalibracao(id) {
      InstrumentoService.buscaUltimoServicoETipoCalibracao(id)
        .then((res) => {
          if (res.data) {
            if (res.data.tipoCalibracao?.id) {
              this.form.calibracao.tipoCalibracaoId =
                res.data.tipoCalibracao?.id;
              this.keyAtualiza++;
            }
            if (res.data.servico?.id) {
              this.temUltimoServicoCalibracao = true;
              this.idUltimoServicoCalibracao = res.data.servico?.id;
            }

            this.buscaDadosListagemCalibracao();
          }
        })
        .finally(() => {});
    },
    buscaDadosListagemCalibracao() {
      if (
        this.form.flagTemServicoCalibracao &&
        this.form.localExecucaoId &&
        this.form.calibracao?.tipoCalibracaoId &&
        this.instrumentoId
      ) {
        this.listarServicosCalibracao();
      }
    },
    listarServicosCalibracao() {
      let params = {
        localExecucaoId: this.form.localExecucaoId,
        tipoCalibracaoId: this.form.calibracao.tipoCalibracaoId,
        instrumentoId: this.instrumentoId,
        tipoServico: 1,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      ServicoService.listaServicosPeloPontoFaixaInstrumento(params)
        .then((res) => {
          const dados = _.cloneDeep(res.data);
          dados.forEach(servico => {
            if(this.dadosCalibracaoSelecionados.servicoId === servico.id)
             return servico.tempoExecucaoCustom = this.dadosCalibracaoSelecionados.horasExecucao;
            servico.tempoExecucaoCustom = servico.tempoExecucao;
          });

          this.tabelaCalibracao.dados = dados;

          if (this.tabelaCalibracao.dados.length == 1) {
            this.tabelaCalibracao.selecionados = this.tabelaCalibracao.dados[0];
          } else if (this.temUltimoServicoCalibracao) {
            this.tabelaCalibracao.dados.forEach((item) => {
              if (item.id == this.idUltimoServicoCalibracao) {
                this.tabelaCalibracao.selecionados = item;
              }
            });
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if (this.dadosCalibracaoSelecionados?.servicoId) this.selecionaCalibracaoDaEdicao();
        });
    },
    selecionaCalibracaoDaEdicao: async function() {
      this.tabelaCalibracao.dados.forEach((item) => {
        if (item.id == this.dadosCalibracaoSelecionados.servicoId) {
          item.tempoExecucaoCustom = this.dadosCalibracaoSelecionados.horasExecucao
          setTimeout(() => this.tabelaCalibracao.selecionados = item, 100);
          }
        });
      setTimeout(this.defineCalibracao, 200);
    },
    validaFormulario() {
      if (
        this.form.flagTemServicoCalibracao &&
        !this.tabelaCalibracao.selecionados
      ) {
        this.toastAlerta(
          this.$t(`modulos.operacao.erros.selecione_servico_calibracao`)
        );
        return;
      }
      this.$emit('valida-dados-analise-tecnica');
    },
    async abreModalFaixa() {
      await InstrumentoService.buscar(this.instrumentoId).then((res) => {
        this.formInstrumento = new InstrumentoModel(res.data);
      });
      await this.$refs['modal-faixa'].abrirModal();
    },
    atualizarInstrumentoFaixas: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.salvar(this.formInstrumento.request)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.operacao.confirmar_atualizacao_faixa`)
          );
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    faixaFormatada(value) {
      return String(value).replaceAll('.', ',');
    },
    defineCalibracao(){
        this.form.dadosCalibracaoResumo = [];
        if (!this.tabelaCalibracao.selecionados) return;
        if (this.tabelaCalibracao.selecionados.minimoPontos > this.form.pontosCalibracaoInstrumento) {
          this.form.pontosExtras = 0;
        } else {
          this.form.pontosExtras = this.form.pontosCalibracaoInstrumento -
            this.tabelaCalibracao.selecionados.minimoPontos;
        }

        this.form.dadosCalibracaoResumo.push(this.tabelaCalibracao.selecionados);

        this.form.calibracao.servicoId = this.tabelaCalibracao.selecionados.id;

        this.form.calibracao.valor = this.precoServicoContrato ? this.precoServicoContrato
          : this.tabelaCalibracao.selecionados.valorServico;

        this.form.calibracao.minimoPontos =
          this.tabelaCalibracao.selecionados.minimoPontos;

        this.form.calibracao.valorPonto =
          this.tabelaCalibracao.selecionados.valorPonto;

        this.form.calibracao.horasExecucaoCustom =
        this.tabelaCalibracao.selecionados.tempoExecucaoCustom;

        this.form.calibracao.horasExecucao =
        this.tabelaCalibracao.selecionados.tempoExecucao;

        this.form.calibracao.valorHora = this.tabelaCalibracao.selecionados.valorHora;

        if (this.tabelaCalibracao.selecionados.valorPonto > 0) {
          this.habilitaPontosItens = false;
        } else {
          this.habilitaPontosItens = true;
          this.form.calibracao.pontosItens = null;
        }
        ++this.keyAtualizaTabela;
        this.$emit('calcula-valor-total');
      }
  },
};
</script>
