export class AnaliseTecnicaModel {
  constructor({
    id,
    instrumentoId,
    ordemServicoId,
    valorTotal,
    calibracao,
    manutencoes,
    pecas,
    localExecucaoId,
    prazoEntregaEmDias,
    flagSemConserto,
    flagGarantia,
    justificativaGarantia,
    flagTemServicoCalibracao,
    pontosCalibracaoInstrumento = 0,
    pontosExtras,
    dadosCalibracaoResumo,
    dadosManutencaoResumo,
    dadosPecasResumo,
    dadosChecklistResumo,
    checklist,
    flagFinalizada,
    tipoPrazoEntrega,
    justificativaSemConserto,
    descricaoJustificativaSemConserto = '',
  }) {
    this.id = id;
    this.instrumentoId = instrumentoId;
    this.ordemServicoId = ordemServicoId;
    this.valorTotal = valorTotal;
    this.calibracao = calibracao;
    this.manutencoes = manutencoes;
    this.pecas = pecas;
    this.localExecucaoId = localExecucaoId;
    this.prazoEntregaEmDias = prazoEntregaEmDias;
    this.flagSemConserto = flagSemConserto;
    this.flagGarantia = flagGarantia;
    this.justificativaGarantia = justificativaGarantia;
    this.flagTemServicoCalibracao = flagTemServicoCalibracao;
    this.pontosCalibracaoInstrumento = pontosCalibracaoInstrumento;
    this.pontosExtras = pontosExtras;
    this.dadosCalibracaoResumo = dadosCalibracaoResumo;
    this.dadosManutencaoResumo = dadosManutencaoResumo;
    this.dadosPecasResumo = dadosPecasResumo;
    this.dadosChecklistResumo = dadosChecklistResumo;
    this.checklist = checklist;
    this.flagFinalizada = flagFinalizada;
    this.tipoPrazoEntrega = tipoPrazoEntrega;
    this.justificativaSemConserto = justificativaSemConserto;
    this.descricaoJustificativaSemConserto = descricaoJustificativaSemConserto;
  }
}
