<template lang="">
  <modal-padrao
    ref="modal-vista-Explodida"
    max-width="90%"
    :titulo="$t('modulos.operacao.formulario.selecione_pecas')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :mostrar-botoes="false"
    @modalFechado="modalFechado"
    style="padding: 0.5rem"
  >
    <div class="main-container">
      <v-hotspot
        :init-options="hotspotConfig"
        :overlay-text="$t('modulos.modelo_instrumento.imagem')"
        :tela-edicao="ehTelaEdicao"
        :hotspots-db="hobspots"
        @getHotspotClickedData="getHotspotClickedData"
      />
    </div>
    <span class="ui_alert-hotspot-dbclick"
      >* {{ $t('modulos.operacao.formulario.alerta_click_duplo') }}</span
    >
  </modal-padrao>
</template>
<script>
import VistaExplodidaService from '@/common/services/cadastros/VistaExplodidaService';
import VueHotspot from '@/components/VueHotspot/VueHotspot.vue';

export default {
  props: {
    idModeloInstrumento: { type: String, default: '' },
  },
  components: { 'v-hotspot': VueHotspot },
  data() {
    return {
      formularioModal: true,
      hobspots: [],
      ehTelaEdicao: true,
      hotspotConfig: {
        image:
          'https://via.placeholder.com/600x500?text=Oops!+image+not+found...',
        editable: false,
        interactivity: 'hover',
      },
    };
  },
  mounted() {
    this.listarHotspots();
  },
  watch: {
    idModeloInstrumento() {
      this.listarHotspots();
    },
  },

  methods: {
    getHotspotClickedData(hotspot) {
      this.$emit('hotspotClickedDados', { ...hotspot.peca });
    },
    modalFechado(data) {
      this.$emit('modalFechado', data);
    },
    formataHotspotDados(modeloInstrumento) {
      this.hotspotConfig.image = modeloInstrumento.imagem;

      return modeloInstrumento.vistasExplodidas.map((ponto) => {
        const { coordenadaX, coordenadaY, iterador, item, pontoId } = ponto;

        const {
          id: itemId,
          codigo,
          descricao,
          participanteFabricante,
        } = item || {};
        const fabricante = participanteFabricante?.nome || '-';

        return {
          x: coordenadaX,
          y: coordenadaY,
          iterador,
          itemId,
          codigo: codigo || '',
          fabricante,
          descricao,
          pontoId,
          peca: { ...item },
        };
      });
    },

    abrirModal: async function () {
      this.$refs['modal-vista-Explodida'].abrirModal();
    },
    fecharModal() {
      this.$refs['modal-vista-Explodida'].fecharModal();
    },
    atualizaListagemPeca() {
      this.$emit('modal-vista-Explodida');
    },

    listarHotspots() {
      this.$store.dispatch('Layout/iniciarCarregamento');

      VistaExplodidaService.listar(this.idModeloInstrumento)
        .then((res) => {
          this.hobspots = this.formataHotspotDados(res.data);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>

<style>
.main-container {
  padding: 1rem;
}

.ui_alert-hotspot-dbclick {
  opacity: 0.7;
  color: rgb(113, 113, 113);
}
</style>
